<template>
  <div class="home">
    <div class="a">
      <div class="b">
        <el-container>
          <el-header>
            
              <div class="txt">
                <img :src="img1" style="height: 64px; width: 210px">
              </div>

              <div style="float: right; display: flex;margin-left: auto">
                
                <div
                  class="demo-basic--circle"
                  style=" margin-top: 8px"
                >
                  <el-avatar :size="40" :src="circleUrl"></el-avatar>
                </div>

                <div>
                  <el-badge :value="number" class="item">
                    <el-button size="small" type="info" @click="toMsg">评论</el-button>
                  </el-badge>
                </div>
                <div>
                  <el-button
                    type="warning"
                    style="margin-top: 8px; margin-left: 5px"
                    @click="quit"
                    >退出登录</el-button
                  >
                </div>
              </div>
         
          </el-header>
          <el-container>
            <el-aside width="200px">
              <el-menu default-active="1" class="el-menu-vertical-demo">
                <el-menu-item index="1" @click="Musers">
                  <i class="el-icon-menu"></i>
                  <span>用户管理</span>
                </el-menu-item>
                <el-menu-item index="2" @click="Msources">
                  <i class="el-icon-setting"></i>
                  <span>资源管理</span>
                </el-menu-item>
                <el-menu-item index="3" disabled>
                  <i class="el-icon-setting"></i>
                  <span slot="title">待开发</span>
                </el-menu-item>
                <el-menu-item index="4" disabled>
                  <i class="el-icon-setting"></i>
                  <span slot="title">待开发</span>
                </el-menu-item>
              </el-menu>
            </el-aside>
            <el-main>
              <router-view />
            </el-main>
          </el-container>
        </el-container>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import axios from "@/views/JWT/JWTAXIOS";
import Vue from "vue";
import { Route } from "vue-router";

export default Vue.extend({
  data() {
    return {
      number: "",
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
    };
  },
  created() {
    this.fetchData(); // 初始加载数据
  },
  computed:{
    img1(){
      return require("@/img/smiletwo.png")
    }
  },
  methods: {
    toMsg(){
      this.$router.push("/admin/feedback")
    },
    fetchData(){
      const url = `/api/admin/feedback`
      axios.get(url)
      .then(res=>{
        if(res.data.code){
          this.number=res.data.data
          console.log("成功")
        }
        
      }).catch(res=>{
        if(!res.data.code){
          alert("请求失败")
        }
      })
    },
    quit() {
      this.$router.push({ path: "/" });
      sessionStorage.clear();
      console.log(sessionStorage.getItem("token"));
      this.$store.dispatch("logout");
    },
    Musers() {
      if (this.$router.currentRoute.path !== "/admin/user") {
        this.$router.push({ path: "/admin/user" });
      }
    },
    Msources() {
      if (this.$router.currentRoute.path !== "/admin/source") {
        this.$router.push({ path: "/admin/source" });
      }
    },
  },
});
</script>

<style scoped>
.el-header {
  background-color: #edf7f7;
  color: #333;
  display: flex;
  border: 1px;
  padding: 2px;
}

.el-aside {
  text-align: center;
  line-height: 100vh;
}

.el-main {
  background-color: #e9eef3;

  text-align: center;
  display: flex;
}

body > .el-container {
  margin-bottom: 100%;
}
.el-menu-vertical-demo {
  height: 89vh;
}
.txt {
 /* 字体加粗 */
}
.block {
  text-align: right;
}
.item {
  margin-top: 14px;
  margin-right: 16px;
 margin-left: 10px;
}
</style>
