import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import AdminView from '../views/Admin/AdminView.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/test1',
    component: () => import('../views/test/TestView copy.vue')
  },
  {
    path: '/test',
    component: () => import('../views/test/TestView.vue')
  },
  {
    path: '/',
    name: 'home',
    redirect: '/login'
  },
  {
    path: '/admin',
    name: "admin",

    redirect: '/admin/user',
    component: AdminView,

    children: [
      {
        path: '/admin/user',
        meta: { requiresAuth: true, permission: true, },
        component: () => import('../views/Admin/MUsersView.vue')
      },
      {
        path: '/admin/source',
        meta: { requiresAuth: true, permission: true, },
        component: () => import('../views/Admin/MSourcesView.vue'),
      }
    ]
  },
  {
    path: '/admin/feedback',
    meta: { requiresAuth: true, permission: true, },
    component: () => import("../views/Admin/AFeedbackView.vue")
  },
  {
    path: '/user',
    name: "user",
    component: () => import('../views/User/AdminView.vue'),

    redirect: '/user/source',
    children: [
      {
        path: '/user/source',
        meta: {
          requiresAuth: true,
          
        },
        component: () => import('../views/User/USourcesView.vue')
      }
    ]
  },
  {
    path: "/user/ucenter",
    component: () => import("../views/User/UcenterView.vue"),
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: "/user/ucollect",
    component: () => import("../views/User/UCollectView.vue"),
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: "/user/ufeedback",
    component: () => import("../views/User/UFeedbackView.vue") ,
    meta:{
      requiresAuth: true,
    }
  },

  {
    path: '/login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/404',
    component: () => import('../views/404View.vue')
  },
  {
    path: '*',
    redirect: '/404'
  },


]


const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.state.isAuthenticated; // 获取认证状态

  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    if (to.path === '/login') {
      next();//如果需要认证且未认证，跳转到登录页(防止重定向循环，判断是否在登录界面)
    } else{
      next('/login');
    }

  } else if (to.matched.some(record => record.meta.permission)) {
    const type = sessionStorage.getItem("type");
    if (type == "1") {
      next();
    }
    else {
      alert("没有权限！")
      next('/user');// 继续导航
    }
  }
  else {
    next();

  }
});

export default router
