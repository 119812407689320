  import axios from 'axios';

  const instance = axios.create({
    baseURL: '', // 替换为你的 API 基础 URL
  });

  instance.interceptors.request.use(
    config => {
      const token = sessionStorage.getItem('token'); // 从 localStorage 获取 token
      if (!config.url?.includes('/login')) { // 检查路径是否是 "/login"
        console.log("添加jwt")
        config.headers.token = `${token}`; // 添加 token 到请求头
        console.log(`${token}`)
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  export default instance;