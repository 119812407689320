import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)



export default new Vuex.Store({
  state: {
    isAuthenticated: sessionStorage.getItem('isAuthenticated') === 'true', // 初始状态
  },
  getters: {
  },
  mutations: {
    login(state) {
      state.isAuthenticated = sessionStorage.getItem("isAuthenticated")=="true"; // 登录
    },
    logout(state) {
      state.isAuthenticated = false; // 登出
    }
  },
  actions: {
    login({ commit }) {
      commit('login'); // 触发登录 mutation
    },
    logout({ commit }) {
      commit('logout'); // 触发登出 mutation
    }
  },
  modules: {
  }
})



